import i18next from 'i18next';

export function getCurrentLanguage(source){
  if (localStorage.getItem("currentSettings") === null) {
    return 'en'
  }
  let currentSettings = JSON.parse(localStorage.getItem('currentSettings'))
  let language = 'en'
  if(source in currentSettings){
    language = currentSettings[source]['language']
  }
  return (language ? language : 'en')
}

export function formatUrl(url) {
  return (url.indexOf('://') === -1) ? 'https://' + url : url;
}

export function getCookie(cookie_name){
  return get_cookie(cookie_name);
}

export function gpcLocalization(source, language) {
  let gpcArg = getGPCDescriptionKey(language)
  let gpcBrickDescription = source[gpcArg]
  if(gpcBrickDescription === undefined){
    gpcBrickDescription = source["gpcBrickDescriptionEn"]
  }
  return gpcBrickDescription
}

export function getGPCDescriptionKey(language) {  
  let convertedCode = convertCode(language)
  let gpcArg = 'gpcBrickDescription' + convertedCode
  return gpcArg
}

function convertCode(language) {  
  let startChar = language[0]
  startChar = startChar.toUpperCase()
  let endChar  =  language[1]
  let convertedLanguage = startChar + endChar
  return convertedLanguage
}

export function getBrickCode(record) {
  let gpcBrickCode = ''
  if('gdsnTradeItemClassification' in record &&  'gpcCategoryCode' in record.gdsnTradeItemClassification){
    gpcBrickCode = record.gdsnTradeItemClassification.gpcCategoryCode
  } else if (process.env.REACT_APP_SOURCE === 'migros'){ 
    if ('gpcCategoryCode' in record){
      gpcBrickCode = record.gpcCategoryCode
    } else if('gpcBrick' in record){
      gpcBrickCode = record.gpcBrick
    }
  }
  return gpcBrickCode
}

export function getBrickDescription(completeGPCData, language, gpcBrickCode) {
  let defaultLanguage = 'en'
  let gpcBrickDescription = ''

  if(completeGPCData[language] !== undefined){
    if(completeGPCData[language][gpcBrickCode] !== undefined){
      gpcBrickDescription = completeGPCData[language][gpcBrickCode]['brick_desc']
    }else{
      gpcBrickDescription = "undefined"
    }
  }else if(completeGPCData[defaultLanguage] !== undefined){
    if(completeGPCData[defaultLanguage][gpcBrickCode] !== undefined){
      gpcBrickDescription = completeGPCData[defaultLanguage][gpcBrickCode]['brick_desc']
    }else{
      gpcBrickDescription = "undefined"
    }
  }else{
    gpcBrickDescription = "undefined"
  }  
  return gpcBrickDescription
}

export function getGPCDescriptionWithBrickCheck(gpcBrickCode,completeGPCData,language,defaultLanguage){
  let gpcBrickDescription = ''
  gpcBrickDescription = getBrickDescription(completeGPCData,language,gpcBrickCode)
  if(gpcBrickDescription === ''){
    gpcBrickDescription = getBrickDescription(completeGPCData,defaultLanguage,gpcBrickCode)
    if(gpcBrickDescription === ''){
      gpcBrickDescription = "undefined"
    }                
  }
  return gpcBrickDescription;
}

function get_cookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function openInNewTab(url){
  const a = document.createElement("a");
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.click();
}

export const keyExists = (obj, key) => {
  if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
    return false;
  }
  else if (obj.hasOwnProperty(key)) {
    return true;
  }
  else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key);
      if (result) {
        return result;
      }
    }
  }
  else {
    for (const k in obj) {
      const result = keyExists(obj[k], key);
      if (result) {
        return result;
      }
    }
  }

  return false;
};

export function translateKey(key, prefix){
  if (key == null) {
    return '';
  }
  const key_split = key.split('/')
  if(key_split.length > 1) {
    const keys = key_split.map((key) => {
      const newKey = `${prefix}.${key.trim()}`
      const label = (i18next.exists(newKey)) ? i18next.t(newKey) : key.trim()
      return label
    })
    return keys.join(" / ")
  }
  if(key.slice(-1) === "."){
    key = key.slice(0, -1)
    const newKey = `${prefix}.${key}`
    const label = (i18next.exists(newKey)) ? i18next.t(newKey) : key
    return label
  }
  if(!isNaN(key.slice(-1))){
    return i18next.t(key.slice(0, -2)) + ' ' + key.slice(-1);
  } else {
    const newKey = `${prefix}.${key}`
    const label = (i18next.exists(newKey)) ? i18next.t(newKey) : key
    return label;
  }
}

export function searchQueryFilterItems(){
  return {
    "all": i18next.t("searchFilterDropDown.all"),
    "gtin": i18next.t("searchFilterDropDown.gtin"),
    "gpc": i18next.t("searchFilterDropDown.gpc"),
    "supplier": i18next.t("searchFilterDropDown.supplier"),
    "manufacturer": i18next.t("searchFilterDropDown.manufacturer"),
    "brand": i18next.t("searchFilterDropDown.brand"),
    "tii": i18next.t("searchFilterDropDown.tii")
  }
}

export function exportProductJSON(product) {
  let fileName = product['gtin'];
  let jsonString = product["originalJson"]
  let content = JSON.stringify(JSON.parse(jsonString), null, 2);
  var blob = new Blob([content], { type: 'text/json' });
  var url = URL.createObjectURL(blob);
  var tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', fileName + '.json');
  tempLink.click();
  URL.revokeObjectURL(url)
}

export function exportProductXML(product){  
  let fileName = product['gtin'];
  let source = process.env.REACT_APP_SOURCE
  let content = null
  if (source === 'migros') {
    content = product["content"]
  } else {
    content = product["originalXml"]
  }
  var blob = new Blob([content], { type: 'text/xml' });
  var url = URL.createObjectURL(blob);
  var tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', fileName + '.xml');
  tempLink.click();
  URL.revokeObjectURL(url)
}

export function migrosSearchQueryFilterItems(){
  return {
    "all": i18next.t("searchFilterDropDown.all"),
    "gtin": i18next.t("searchFilterDropDown.gtin"),
    "gpc": i18next.t("searchFilterDropDown.gpc"),
    "manufacturer": i18next.t("searchFilterDropDown.manufacturer"),
    "brand": i18next.t("searchFilterDropDown.brand"),
    "tii": i18next.t("searchFilterDropDown.tii")
  }
}

export function euvinoClientList(){
  return {
    "RG": "Radeberger",
    "GH": "Getränke Hoffmann"
  }
}

function dataSourceLabelList(){
  return {
    "GDSN": "GDSN",
    "SAP": "SAP",
    "PIM": "PIM",
    "markant": "Markant",
    "OFF": "OFF",
    "EuvinoFP": "Euvino",
    "EuvinoRG": "Euvino RG",
    "EuvinoGH": "Euvino GH"
  }
}

export function getDataSourceLabel(dataSource){
  const dataSourceLabels = dataSourceLabelList();

  let sourceLabel = ''
  if( dataSource in dataSourceLabels ) {
    sourceLabel = dataSourceLabels[dataSource]
  } else {
    sourceLabel = dataSource
  }
  return sourceLabel
}

export function getDistinctValues(arr, property) {
  return [...new Set(arr.map(item => item[property]))];
}

export function getNumCountryCode(countryCode){
  const countryCodes = {
    "DE": "276",
    "PL": "616",
    "CZ": "203",
    "BG": "100",
    "SK": "703",
    "RO": "642",
    "MD": "498",
    "HR": "191"
  }

  if ( countryCodes.hasOwnProperty(countryCode) ) {
    return countryCodes[countryCode]
  } else {
    return ''
  }
}

export function getBaseUrl() {
  return window.location.origin;
}

export function getPageHeading() {
  let source = process.env.REACT_APP_SOURCE;
  let mode = process.env.REACT_APP_MODE;
  let pageHeading = ""
  let format = ""
  let testModeText = ""

  const currentUrl = window.location.href;
  
  if (source === "schwarz" && mode === 'lidl') {pageHeading = "Lidl"} 
  else pageHeading = source 
  
  if (['-a.bayard'].find(substring => currentUrl.includes(substring))) { pageHeading = "PDP - A" }
  else if (['-q.bayard'].find(substring => currentUrl.includes(substring))) { pageHeading = "PDP - Q" }
  else if (['-mvp.bayard'].find(substring => currentUrl.includes(substring))) { pageHeading = "PDP - MVP" }  

  if (['aldi', 'bc'].includes(source) || (source === "schwarz" && mode !== 'lidl')){
    format = "all upper"
  } else {
    format = "normal"
  } 
  
  if (mode === "test"){
    testModeText = " - Test"
  } 

  pageHeading = formatWord(pageHeading, format) + testModeText

  return pageHeading;
}

export function formatWord(word, format) {
  if (!word) return ""; // Handle empty or undefined input
  
  switch (format) {
    case "normal":
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      
    case "all lower":
      return word.toLowerCase();
      
    case "all upper":
      return word.toUpperCase();
      
    case "camel":
      return word
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => 
          index === 0 ? match.toLowerCase() : match.toUpperCase()
        )
        .replace(/\s+/g, '');

    default:
      return word; // Return the original word if format is unrecognized
  }
}
  
export function getNumberWithSeparator(language, number) {
  let separatorLanguage = 'en-UK'
  let numberWithSeparator = number

  switch (language) {
    // en => countries using UK english, use 1,234,567.89 number format
    case 'en':
      separatorLanguage = 'en-UK'; 
      break;
  
    // de, hr, ro => all of these countries use 1.234.567,89 number format  
    case 'de':
    case 'hr':
    case 'ro':    
      separatorLanguage = 'de-DE';
      break;
    
    // bg, cz, pl, sk => all of these countries use 1 234 567.89 number format  
    case 'bg':
    case 'cz':
    case 'fr':  
    case 'pl':
    case 'sk':
      separatorLanguage = 'bg-BG';
      break;            
            
    default:
      separatorLanguage = 'en-UK';
  }

  numberWithSeparator = new Intl.NumberFormat(separatorLanguage).format(numberWithSeparator)
  return numberWithSeparator;
}

export function getManufacturerColumn() {
  const source = process.env.REACT_APP_SOURCE;
  let columnName = '';

  switch (source) {
    case 'migros':  
      columnName = process.env.REACT_APP_MIGROS_SR_MANUFACTURER_COLUMN_NAME
      break;
    case 'schwarz':
      if (['lidl'].includes(process.env.REACT_APP_MODE)){
        columnName = process.env.REACT_APP_SCHWARZ_SR_MANUFACTURER_COLUMN_NAME_LIDL
      } else {
        columnName = process.env.REACT_APP_SCHWARZ_SR_MANUFACTURER_COLUMN_NAME_MVP
      }
      break
    case 'radeberger':
      columnName = process.env.REACT_APP_RADEBERGER_SR_MANUFACTURER_COLUMN_NAME
      break
    case 'flaschenpost':
      columnName = process.env.REACT_APP_FLASCHENPOST_SR_MANUFACTURER_COLUMN_NAME
      break
    case 'bc':
      columnName = process.env.REACT_APP_BC_SR_MANUFACTURER_COLUMN_NAME
      break
    case 'aldi':
      columnName = process.env.REACT_APP_ALDI_SR_MANUFACTURER_COLUMN_NAME
      break
    case 'markant-book':
      columnName = process.env.REACT_APP_MARKANT_SR_MANUFACTURER_COLUMN_NAME
      break
    default:
      columnName = 'manufacturer';
      break;
  }

  return columnName;
}