import { of } from 'rxjs';
import APIService from './base/APIService.js';
import { getBrickDescription, getBrickCode } from '../../../common/helpers/Common.js';

export default class BCAPIService extends APIService {

    API_PATH = process.env.REACT_APP_MODE === 'prod' ? process.env.REACT_APP_BC_CONTENT_AGGREGATION_URL_PROD
        : process.env.REACT_APP_BC_CONTENT_AGGREGATION_URL;
    DEFAULT_TARGET_MARKET = '276';

    getProductDetailsFromEs(productId, origin, contentLanguage, labelLanguage) {
        return this.postWithUIToken('get-product-details-from-es/', JSON.stringify({ productId: productId, origin: origin, 
            contentLanguage: contentLanguage, labelLanguage: labelLanguage }));
    }

    getGPCData(gpcCategoryCode) {
        return this.postWithUIToken('get-gpc-data/', JSON.stringify({ gpcCode: gpcCategoryCode }));
    }

    getCompleteLangGPCData() {
        return this.getWithUIToken('get-all-gpc-data/');
    }

    downloadOriginalMessage(productId, source) {
        return this.postWithUIToken('download-original-message/', JSON.stringify({id: productId, source: source}))
    }

    getTargetMarkets() {
        let query = {
            aggs: {
                targetMarkets: {
                    terms: {
                        field: "targetMarket.targetMarketCountryCode",
                        size: 10000
                    }
                }
            }
        }
        return this.postWithUIToken('get-target-markets/', JSON.stringify(query));
    }

    getDataCount() {
        return this.getWithUIToken('get-sources/');
    }

    getDashboardData() {
        return this.getWithUIToken('get-dashboard-data/');
    }

    getGdsnTradeItemHierarchy(gtin, gln, targetMarket, source) {
        return this.postWithUIToken('get-gdsn-product-hierarchy/', JSON.stringify({gtin: gtin, gln: gln, targetMarket: targetMarket, source: source}));
    }
    exportData(gtinList, tm_list, language) {
        return this.postWithUIToken('export-gdsn-artikel-pass/', JSON.stringify({ gtinList: gtinList, tm_list: tm_list, language: language }));
    }

    /**
    * GDSN Subscription-related
    */

    getValidTargetMarketsForGdsnSubscriptions() {
        return of(new Response('{"data":["276"]}', { status: 200 })).toPromise();
    }

    getValidSubscriberGlns() {
        return of(new Response('{"data":["4260590812223"]}', { status: 200 })).toPromise();
    }

    createGdsnSubscription(gdsnSubscription) {
        let path = 'create-subscription/';
        return this.postWithUIToken(path, JSON.stringify(gdsnSubscription));
    }

    deleteGdsnSubscription(gdsnSubscription) {
        let path = 'delete-subscription/';
        return this.deleteWithUIToken(path, JSON.stringify(gdsnSubscription));
    }

    /**
     * search related
     */

    search(keyword, size, offset, logicalOperator, origin, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches, childTradeItemRadio, searchQueryFilter) {
        let keywords = keyword.split(' ')
        let negationKeywords = []
        let filterAttributesList = []
        let searchQuery = ''
        let targetMarketQuery = ''
        let originQuery = ''
        let searchQueryFieldList = []
        for (let i = 0; i < keywords.length; i++) {
            if (keywords[i][0] === '-') {
                if (keywords[i].length > 1) {
                    negationKeywords.push(keywords[i])
                }
            }
            else {
                if (keywords[i].length > 0) {
                    if (keywords[i].includes(':')) {
                        if (keywords[i].split(':')[1].length > 0) {
                            let filterAttributePair = keywords[i].split(':')
                            let filterCondition = {}
                            let attribute = filterAttributePair[1].replace('-', ' * ')
                            filterCondition = this.mapFilterAttributes(filterAttributePair[0], attribute, exactSearch)
                            filterAttributesList.push(filterCondition)
                            if (exactSearch) {
                                searchQuery = searchQuery + ' (' + filterAttributePair[1] + ') ' + logicalOperator
                            } else {
                                searchQuery = searchQuery + ' (*' + filterAttributePair[1] + '*) ' + logicalOperator
                            }
                        }
                    }
                    else {
                        if (exactSearch) {
                            searchQuery = searchQuery + ' (' + keywords[i] + ') ' + logicalOperator
                        } else {
                            searchQuery = searchQuery + ' (*' + keywords[i] + '*) ' + logicalOperator
                        }
                    }
                }
            }
        }
        if (logicalOperator === 'AND') {
            searchQuery = searchQuery.slice(0, -4)
        } else if (logicalOperator === 'OR') {
            searchQuery = searchQuery.slice(0, -3)
        }
        for (let i = 0; i < negationKeywords.length; i++) {
            searchQuery = searchQuery + ' ' + negationKeywords[i]
        }
        for (let i = 0; i < targetMarketSwitches.length; i++) {
            for (let key in targetMarketSwitches[i]) {
                if (targetMarketSwitches[i][key] === true) {
                    targetMarketQuery = targetMarketQuery + ' OR ' + key
                }
            }
        }
        for (let i = 0; i < origin.length; i++) {
            for (let key in origin[i]) {
                if (origin[i][key] === true) {
                    originQuery = originQuery + ' OR ' + key
                }
            }
        }
        switch (searchQueryFilter) {
            case 'all':
                if (childTradeItemRadio === 'base') {
                    searchQueryFieldList.push('gtin')
                } else if (childTradeItemRadio === 'child') {
                    searchQueryFieldList.push('nextLowerLevelTradeItemInformation.childTradeItem.gtin')
                }  else if (childTradeItemRadio === 'both') {
                    searchQueryFieldList.push('gtin')
                    searchQueryFieldList.push('nextLowerLevelTradeItemInformation.childTradeItem.gtin')
                }
                searchQueryFieldList.push('tradeItemInformation', 'manufacturerOfTradeItem', 'informationProviderOfTradeItem', 'gpcBrickCode', 'brandOwner', 'CustomAttribute')
                break;
            case 'gtin':
                    if (childTradeItemRadio === 'base') {
                        searchQueryFieldList.push('gtin')
                    } else if (childTradeItemRadio === 'child') {
                        searchQueryFieldList.push('nextLowerLevelTradeItemInformation.childTradeItem.gtin')
                    }  else if (childTradeItemRadio === 'both') {
                        searchQueryFieldList.push('gtin')
                        searchQueryFieldList.push('nextLowerLevelTradeItemInformation.childTradeItem.gtin')
                    }
                break;
            case "supplier":
                searchQueryFieldList = ['informationProviderOfTradeItem']
                break;
            case "manufacturer":
                searchQueryFieldList = ['manufacturerOfTradeItem']
                break;
            case "tii":
                searchQueryFieldList = ['tradeItemInformation']
                break;
            case "gpc":
                searchQueryFieldList = ['gpcBrickCode']
                break;
            case "ca":
                searchQueryFieldList = ['CustomAttribute']
                break;
            case "brand":
                searchQueryFieldList = ['brandOwner']
                break;
            default:
                console.log('inside switch: incorrect filter: ' +searchQueryFilter);
                break;
        }
        
        originQuery = originQuery.substring(4)
        targetMarketQuery = targetMarketQuery.substring(4)
        let isConsumerUnitOnly_query = {
                'query_string': {
                     'query': isConsumerUnit,
                     'fields': ['isTradeItemAConsumerUnit']
                }
        }
        let query = {
            query: {
                bool: {
                    must: [
                        {
                            'query_string': {
                                'query': descriptorCode,
                                'fields': ['tradeItemUnitDescriptorCode.__value__']
                            }
                        },
                        {
                            'query_string': {
                                'query': originQuery,
                                'fields': ['source']
                            }
                        },
                        {
                            'query_string': {
                                'query': targetMarketQuery,
                                'fields': ['targetMarket.targetMarketCountryCode']
                            }
                        },
                        {
                            query_string: {
                                'query': searchQuery,
                                'fields': searchQueryFieldList
                            }
                        }
                    ]
                }
            },
            'from': offset,
            'size': size
        }
        if(isConsumerUnit){
            query['query']['bool']['must'].push(isConsumerUnitOnly_query)
        }
        if (filterAttributesList.length > 0) {
            let condition = ''
            if (logicalOperator === 'AND') {
                condition = 'must'
            } else if (logicalOperator === 'OR') {
                condition = 'should'
            }
            let filterObject = {}
            filterObject['bool'] = {}
            filterObject['bool'][condition] = []
            for (let i = 0; i < filterAttributesList.length; i++) {
                filterObject['bool'][condition].push(filterAttributesList[i])
            }
            query['query']['bool']['must'].push(filterObject)
        }
        return this.post('_ui_search', JSON.stringify(query));
    }


    mapFilterAttributes(filter, attribute, exactSearch) {
        let filterCondition = {}
        if (filter === 'productDescription') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.descriptionShort.__value__']
        }
        else if (filter === 'productName') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.functionalName.__value__']
        }
        else if (filter === 'manufacturer') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['manufacturerOfTradeItem.partyName', 'informationProviderOfTradeItem.partyName']
        }
        else if (filter === 'gln') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['manufacturerOfTradeItem.gln', 'informationProviderOfTradeItem.gln']
        }
        else if (filter === 'gpc') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['gpcBrickCode', 'gpcBrickDescriptionEn', 'gpcClassCode', 'gpcClassDescriptionDe', 'gpcFamilyCode', 'gpcFamilyDescriptionDe', 'gpcSegmentCode', 'gpcSegmentDescriptionDe']
        }
        else if (filter === 'brandName') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName']
        }
        else if (filter === 'targetMarket') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['targetMarket.targetMarketCountryCode']
        }
        else if (filter === 'isWithdrawn') {
            filterCondition['term'] = {
                'isWithdrawn': attribute
            }
        }
        else {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = [filter]
        }
        return filterCondition
    }

    handleGPCManufacturerFilterButtons(theFilterList, originalRecordList,completeGPCData, language) 
    {
        const theManufacturers = theFilterList['manufacturer']
        const theGPCList = theFilterList['gpc']
        let brickDescription = ''
        let gpcBrickCode = ''

        let recordList = originalRecordList.filter(function(product) {
            gpcBrickCode = getBrickCode(product)             
            brickDescription = getBrickDescription(completeGPCData,language,gpcBrickCode)               
            const gpcCode = gpcBrickCode + ' - ' + brickDescription

            if( 'manufacturerOfTradeItem' in product && 'partyName' in product['manufacturerOfTradeItem'][0] ) {
                return theManufacturers.includes(product['manufacturerOfTradeItem'][0].partyName) && theGPCList.includes(gpcCode)
            } else if('informationProviderOfTradeItem' in product && 'partyName' in product['informationProviderOfTradeItem']) {
                return theManufacturers.includes(product['informationProviderOfTradeItem'].partyName) && theGPCList.includes(gpcCode)
            }
            return true
        })
        return { records: recordList };
    }

    getUserRights(username) {
        return this.getWithUIToken('get-user-rights/' + username);
    }

    getRightsByRole(userRole) {
        return this.getWithUIToken('get-user-rights-by-role/' + userRole);
    }
    
    downloadProductPdf(documentUrl) {
        return this.postWithUIToken('download-item-pdf/', JSON.stringify({ documentUrl: documentUrl}));
    }    

}
