import i18next from 'i18next';
import Modal from 'react-modal';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import ReImportForm from './ReImportForm';
import AppBar from '@mui/material/AppBar';
import ExportGlnList from './ExportGlnList'
import Tooltip from '@mui/material/Tooltip';
import ExportRequest from './ExportRequest';
import { palette } from '../../../assets/Palette';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const DataReImportModal = ({toggleDataReImportModal, handleDataReImportModalClose, apiService, snackBarOpen}) => {
	const [valueTab, setValueTab] = useState(0);

	const handleTabChange = (event, value) => {
		setValueTab(value)
	}

	const a11yProps = (index) => {
		return {
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<Modal
			isOpen={toggleDataReImportModal}
			style={{ overlay: { zIndex: 1000 } }}
			ariaHideApp={false}
		>
			<div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', margionTop: '-15px' }}>
				<Grid container>
					<Grid item  style={{paddingTop: "11px"}}>
						<Tooltip title={i18next.t('markantReImportModal.close')}> 
							<IconButton
								aria-label="Close"
								className="close"
								data-dismiss="modal"
								type="button"
								onClick={handleDataReImportModalClose}
								style={{ outline: 'none',  marginRight: '8%'}}
								size="large">
								<CloseRoundedIcon style={{ color: palette.warning.close }} />
							</IconButton>
						</Tooltip>  
					</Grid>
				</Grid>
			</div>
			<div style={{paddingLeft: '16px'}}>
				<h2 key={'h1'} id="data-request-modal-heading">Markant {i18next.t('markantReImportModal.modalHeading')}</h2>
				<Box sx={{ bgcolor: 'background.paper', width: "100%", letterSpacing: "0.02857em !important" }}>
					<AppBar position="static">
						<Tabs 
							value={valueTab} 
							onChange={handleTabChange} 
							aria-label={i18next.t('markantReImportModal.dataRequestModalHeading')}
							indicatorColor="secondary"
							textColor="inherit"
							>
								<Tab 
									label={i18next.t('markantReImportModal.importForm')} 
									{...a11yProps(0)} 
									sx={{
										'&:focus': {
											outline: 'none',
										},
									}}
									id={"data-re-import-form-tab"}
								/>
								<Tab 
									label={i18next.t('markantReImportModal.exportRequest')} 
									{...a11yProps(1)} 
									sx={{
										'&:focus': {
											outline: 'none',
										},
									}}
									id={"import-list-tab"}
								/>
								<Tab 
									label={i18next.t('markantReImportModal.exportTab')}
									{...a11yProps(2)} 
									sx={{
										'&:focus': {
											outline: 'none',
										},
									}}
									id={"export-list-tab"}
								/>
						</Tabs>
					</AppBar>
					<TabPanel value={valueTab} index={0}>
						<ReImportForm appContext={apiService} handleSnackBarOpen={snackBarOpen} />
					</TabPanel>
					<TabPanel value={valueTab} index={1}>
						<ExportRequest appContext={apiService} />
					</TabPanel>
					<TabPanel value={valueTab} index={2}>
						<ExportGlnList appContext={apiService} />
					</TabPanel>
				</Box>
			</div>
		</Modal>
	);
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>{children}</Box>
			)}
		</div>
	);
}

export default DataReImportModal;
