import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Modal from 'react-modal';
import Checkbox from '@mui/material/Checkbox';
import { withTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette';
import React, { useState, useEffect } from 'react';
import { Table } from "reactstrap";
import { Oval } from 'react-loader-spinner';
import { CustomerContext } from "../../common/CustomerContext.js";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { translateKey } from '../../common/helpers/Common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';


const appMode = process.env.REACT_APP_MODE

const tradeItemHierarchyModalStyle = {
    content: {
        top: '25%',
        left: '50%',
        right: '30%',
        marginRight: '-50%',
        transform: 'translate(-50%, -20%)',
        overlay: { zIndex: 1000 }
    }
};

// Function to filter items by gtin value
const filterByGtin = (data, gtin) => {
    return data.filter(item => {
        if (item.gtin === gtin) {
            return true;
        }
        return false;
    });
};

// Function to find parent object of current object
function findParentItem(obj, childGtin, parent = null) {
    // Check if current object has the target child
    if (obj.gtin === childGtin) {
        return parent;
    }

    // Check if parentTradeItem exists and iterate through its items
    if (obj.parentTradeItem) {
        for (let item of obj.parentTradeItem) {
            // Recursive call to find in nested levels
            const result = findParentItem(item, childGtin, obj);
            if (result) return result;
        }
    }

    // Return null if no parent is found
    return null;
}

// Function to find child item of current object by parent gtin
function getQuantityOfTradeItem(parentItem, item) {
    if (parentItem !== null) {
        const gtin = parentItem.gtin;
        //  Call to filter item by gtin
        const tradeItem =  filterByGtin(item['childTradeItem'], gtin)
        if (tradeItem.length > 0) {
            return tradeItem[0]['quantityOfTradeItem']
        }
        return null
    }
    return null
}

function Folder({ item, indent, addToSelection, removeFromSelection, isHierarchyOpen, searchItem, hierarchyTree}) {
    const [isOpen, setIsOpen] = useState(isHierarchyOpen);
    const [isChecked, setIsChecked] = useState(false);
    const prefix = 'tradeItemHierarchyModal'
    
    useEffect(() => {
        setIsOpen(isHierarchyOpen);
      }, [isHierarchyOpen]);
  
    const toggleFolder = () => {
        setIsOpen(!isOpen);
    };

    const checkItem = () => {
        if(isChecked)removeFromSelection(item.id);
        else addToSelection(item.id);
        setIsChecked(!isChecked)
    }

    if (item.parentTradeItem.length === 0) indent++
    let margin = `${indent * 20}px`

    const parentTradeItem = findParentItem(hierarchyTree, item.gtin)
    const quantityOfTradeItem = getQuantityOfTradeItem(parentTradeItem, item)

    return (
        <>  
            <tr style={{ cursor: 'pointer', fontWeight: 'bold', width: '40%'} } id={"trade-item-hierarchy-row-"+item.id}>
                <td key={item.gtin}>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: margin, marginRight: '20px', color: palette.text.main, fontSize: 'large', width: '40%'}}>
                        {item.parentTradeItem.length > 0 && (isOpen ? (<FolderOpenIcon onClick={toggleFolder}/>) : (<FolderIcon onClick={toggleFolder} />))}
                        <div><Checkbox color="primary" checked={isChecked} onClick={checkItem} inputProps={{ 'aria-label': 'secondary checkbox' }} /></div>
                        <div onClick={ () => searchItem(item.gtin, item.gln, item.targetMarket)}>{item.gtin}</div>
                    </div>
                </td>
                <td key={item.gtin+'-tradeitemUnitdescriptorCode'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{item.tradeItemUnitDescriptorCode}</div>
                </td>
                <td key={item.gtin+'-hasNonGtinLogisticsInformation'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{
                    item.hasNonGtinLogisticsInformation ? translateKey('hasNonGtinLogisticsInformationTrue', prefix) : translateKey('hasNonGtinLogisticsInformationFalse', prefix)}</div>
                </td>
                <td key={item.gtin+'-quantityOfTradeItem'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>
                        { quantityOfTradeItem }
                    </div>
                </td>
            </tr>

            {isOpen && item.parentTradeItem && item.parentTradeItem.length > 0 &&
                item.parentTradeItem.map(parentItem => (
                    <Folder key={parentItem.gtin} item={parentItem} addToSelection={addToSelection} removeFromSelection={removeFromSelection} isHierarchyOpen={isHierarchyOpen} indent={indent+1} searchItem={searchItem} hierarchyTree={hierarchyTree}/>
                ))
            }
        </>
    )
}

function FolderReverseTree({ item, indent, addToSelection, removeFromSelection, isHierarchyOpen, searchItem, gtin}) {
    const [isOpen, setIsOpen] = useState(isHierarchyOpen);
    const [isChecked, setIsChecked] = useState(false);
    const prefix = 'tradeItemHierarchyModal'
    
    useEffect(() => {
        setIsOpen(isHierarchyOpen);
      }, [isHierarchyOpen]);
  
    const toggleFolder = () => {
        setIsOpen(!isOpen);
    };

    const checkItem = () => {
        if(isChecked)removeFromSelection(item.id);
        else addToSelection(item.id);
        setIsChecked(!isChecked)
    }

    if (item.childTradeItem.length === 0) indent++
    let margin = `${indent * 20}px`

    let quantityOfTradeItem = ''
    if ('childTradeItem' in item && item['childTradeItem'].length > 1) {
        quantityOfTradeItem = item.totalQuantityOfNextLowerLevelTradeItem
    } else {
        quantityOfTradeItem = item.quantityOfTradeItem
    }

    let bgColor = ''
    if (item.gtin === gtin) {
        bgColor = "aliceblue"
    }
    return (
        <>  
            <tr style={{ cursor: 'pointer', fontWeight: 'bold', width: '40%', backgroundColor: bgColor} } id={"trade-item-hierarchy-row-"+item.id}>
                <td key={item.gtin}>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: margin, marginRight: '20px', color: palette.text.main, fontSize: 'large', width: '40%'}}>
                        {item.childTradeItem.length > 0 && (isOpen ? (<FolderOpenIcon onClick={toggleFolder}/>) : (<FolderIcon onClick={toggleFolder} />))}
                        <div><Checkbox color="primary" checked={isChecked} onClick={checkItem} inputProps={{ 'aria-label': 'secondary checkbox' }} /></div>
                        <div onClick={ () => searchItem(item.gtin, item.gln, item.targetMarket)}>{item.gtin}</div>
                    </div>
                </td>
                <td key={item.gtin+'-tradeitemUnitdescriptorCode'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{item.tradeItemUnitDescriptorCode}</div>
                </td>
                <td key={item.gtin+'-hasNonGtinLogisticsInformation'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>{
                    item.hasNonGtinLogisticsInformation ? translateKey('hasNonGtinLogisticsInformationTrue', prefix) : translateKey('hasNonGtinLogisticsInformationFalse', prefix)}</div>
                </td>
                <td key={item.gtin+'-quantityOfTradeItem'} >
                    <div style={{ color: palette.text.main,alignItems: 'center', fontSize: 'large', width: '40%', marginRight: '5px' }}>
                        { quantityOfTradeItem }
                    </div>
                </td>
            </tr>

            {isOpen && item.childTradeItem && item.childTradeItem.length > 0 &&
                item.childTradeItem.map(childItem => (
                    <FolderReverseTree key={childItem.gtin} item={childItem} addToSelection={addToSelection} removeFromSelection={removeFromSelection} isHierarchyOpen={isHierarchyOpen} indent={indent+1} searchItem={searchItem} gtin={gtin}/>
                ))
            }
        </>
    )
}



class TradeItemHierarchyModal extends React.Component {

    static contextType = CustomerContext;
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            selectedItems: [],
            hierarchyData: null,
            hierarchyTree: null,
            isHierarchyLoadingComplete: false,
            isHierarchyOpen: true,
            isTopToBottom: localStorage.getItem('isTopToBottom')
        }
        this.toggleIsChecked = this.toggleIsChecked.bind(this);
        this.addToSelection = this.addToSelection.bind(this);
        this.removeFromSelection = this.removeFromSelection.bind(this);
        this.getHierarchyData = this.getHierarchyData.bind(this);
        this.toggleHierarchy = this.toggleHierarchy.bind(this);
        this.toggleHierarchyTree = this.toggleHierarchyTree.bind(this);
        this.searchItem = this.searchItem.bind(this);
        this.keepChildrenWithGtin = this.keepChildrenWithGtin.bind(this);
        this.deepCopy = this.deepCopy.bind(this);
    }

    componentDidMount() {        
        this.getHierarchyData(this.props.product);
        if (localStorage.getItem('isTopToBottom') === null) {
            localStorage.setItem('isTopToBottom', false) 
        }
    }

    addToSelection(item){
        if(this.state.selectedItems.includes(item)) return;
        this.setState( (prevState) => ({
        selectedItems: [...prevState.selectedItems, item]
        })
       )
    }

    removeFromSelection(item) {
        if(!this.state.selectedItems.includes(item)) return;
        this.setState(prevState => ({
                selectedItems: prevState.selectedItems.filter(i => i !== item)
            }));
    }

    toggleIsChecked(){
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    isValidResponse(response) {

        if (Array.isArray(response)) {
            return response.some(item => 
                item !== null && typeof item === 'object' &&
                item.hasOwnProperty('gtin') && item.hasOwnProperty('childTradeItem')
            );
        }
        return false;
    }

    getHierarchyData  = async (product) => {
        this.setState({ isHierarchyLoadingComplete: false })
        try {
            let res = await this.context.apiService.getGdsnTradeItemHierarchy(product['gtin'], product['informationProviderOfTradeItem']['gln'], product['targetMarket']['targetMarketCountryCode'], product['source'])
            if(res.status !== 200) throw "Error while getting GDSN Trade Item Hierarchy Data " + res.status
            res = await res.json()
            if(!this.isValidResponse(res)) throw "Invalid Response from GDSN Trade Item Hierarchy"
            
            let responseData = this.deepCopy(res);
            if(this.props.product['tradeItemUnitDescriptorOriginalCode'] === "BASE_UNIT_OR_EACH") {
                responseData = this.keepChildrenWithGtin(responseData, this.props.product['gtin'])
            }
            
            this.setState({ 
                hierarchyData: res, 
                hierarchyTree: this.reverseHierarchyFromItem(responseData, this.props.product['gtin']),
                isHierarchyLoadingComplete: true
            });
        } catch (error) {
            console.error(error)
            this.setState({ 
                hierarchyData: [], 
                hierarchyTree: []
            });
        } finally {
            this.setState({isHierarchyLoadingComplete: true});
        }
      }

    reverseHierarchyFromItem(hierarchyData, gtin){
        let treeStructure = {}
        for (let i in hierarchyData){
            if(hierarchyData[i].tradeItemUnitDescriptorCode !== 'BASE_UNIT_OR_EACH'){
                gtin = this.getBaseItem(hierarchyData[i])
            }
            if(gtin){
                this.reverseHierarchyFromItemRec(hierarchyData[i], hierarchyData[i], gtin, null, treeStructure)
            }
        }
        return treeStructure
    }

    reverseHierarchyFromItemRec(rootHierarchyData, currentHierarchyData, targetGtin, parentGtin, treeStructure){
        if(!targetGtin) return null;

        if (currentHierarchyData['gtin'] === targetGtin){
            if(parentGtin){
                let parentObj = this.getObjFromTreeByGtin(rootHierarchyData, parentGtin, 'childTradeItem') // search obj in children for rootHierarchyData

                if(!parentObj.hasOwnProperty('parentTradeItem')){
                    parentObj['parentTradeItem'] = [] 
                }
                let targetObj = this.getObjFromTreeByGtin(treeStructure, targetGtin, 'parentTradeItem') // find current object in structure

                if(targetObj){
                    if(targetObj['parentTradeItem'].every( (obj) => parentObj['gtin'] !== obj['gtin'])){
                        targetObj['parentTradeItem'].push(parentObj)
                    } 
                } else {
                    if(Object.keys(treeStructure).length === 0){
                        targetObj = this.getObjFromTreeByGtin(rootHierarchyData, targetGtin, 'childTradeItem')
                        targetObj['parentTradeItem'] = [] 
                        targetObj['parentTradeItem'].push(parentObj)
                        Object.assign(treeStructure, targetObj)
                    }
                }
                this.reverseHierarchyFromItemRec(rootHierarchyData, rootHierarchyData, parentGtin, null, treeStructure) // walk up the tree
            } else {
                if(Object.keys(treeStructure).length === 0){ // base case
                    let targetObj = this.getObjFromTreeByGtin(rootHierarchyData, targetGtin, 'childTradeItem')
                    targetObj['parentTradeItem'] = [] 
                    Object.assign(treeStructure, targetObj)
                }
            }
        } else {
            if(currentHierarchyData['childTradeItem'] && currentHierarchyData['childTradeItem'].length > 0){
                parentGtin = currentHierarchyData['gtin']
                for(let i in currentHierarchyData['childTradeItem']){ // walk down the tree
                    this.reverseHierarchyFromItemRec(rootHierarchyData, currentHierarchyData['childTradeItem'][i], targetGtin, parentGtin, treeStructure)
                }
            }
        }

    }

    getBaseItem(hierarchyData){
        if(hierarchyData.tradeItemUnitDescriptorCode === "BASE_UNIT_OR_EACH"){
            return hierarchyData.gtin;
        } else if (hierarchyData.childTradeItem.length > 0){
            return this.getBaseItem(hierarchyData.childTradeItem[0]);
        } else {
            return null;
        }
    }

    getObjFromTreeByGtin(tree, gtin, searchKey){
        if (typeof tree !== 'object' || Object.keys(tree).length === 0) {
            return null;
        }
    
        if (tree['gtin'] === gtin) {
            return tree;
        }
    
        if(tree.hasOwnProperty(searchKey) && tree[searchKey].length > 0){
            for(let i in tree[searchKey]){
                const result = this.getObjFromTreeByGtin(tree[searchKey][i], gtin, searchKey)
                if(result) return result
            }
        }
        return null
    }

    toggleHierarchy(){
        this.setState({
            isHierarchyOpen: !this.state.isHierarchyOpen
        });
    }

    toggleHierarchyTree(){
        let toggleValue = localStorage.getItem('isTopToBottom')
        toggleValue = toggleValue === "true" ? "false" : "true"
        localStorage.setItem('isTopToBottom', toggleValue)
        this.setState({isTopToBottom: toggleValue})
    }

    searchItem(gtin, gln, targetMarket){
        const dataSource = (appMode === "mvp") ? 'markant' : 'GDSN';

        this.props.callSearchAPI(gtin, '250', '0', 'AND', [{[dataSource]: true}], false, false, false, [{[targetMarket]: true}], false, 'base', 'gtin')
        this.props.toggleTradeItemHierarchyModal()
    }

    // Function to keep lower level child items with a specific GTIN
    keepChildrenWithGtin(data, gtin) {
        if (Array.isArray(data)) {
            data.forEach(item => this.keepChildrenWithGtin(item, gtin));
        } else if (typeof data === 'object' && data !== null) {
            // Recurse into children first
            if (Array.isArray(data.childTradeItem)) {
                data.childTradeItem.forEach(child => this.keepChildrenWithGtin(child, gtin));

                // Keep only children with the given GTIN at the current level
                data.childTradeItem = data.childTradeItem.filter(child => child.gtin === gtin || child.childTradeItem.length > 0);
            }
        }

        return data
    }

    // Function to create a deep copy of an object or array
    deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    render() {
        const prefix = "tradeItemHierarchyModal"
        return (
            <Modal
                isOpen={this.props.tradeItemHierarchyModal}
                toggle={this.props.toggleTradeItemHierarchyModal}
                ariaHideApp={false}
                tabIndex={-1}
                style={tradeItemHierarchyModalStyle}
            >
                <div className='TradeItemHierarchyModal-container'>
                    <div className='TradeItemHierarchyModal-header'>
                    </div>
                    <p/>
                    <div className='TradeItemHierarchyModal-content'>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
                        <Tooltip title={'searchResults.close'}>
                            <IconButton
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.props.toggleTradeItemHierarchyModal}
                                style={{ outline: 'none', marginTop: '13%' }}
                                size="large">
                                <CloseRoundedIcon style={{ color: palette.warning.close }} />
                            </IconButton>
                        </Tooltip>
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item size={9}>
                            <h2 key={'h1'} id="trade-item-hierarchy-heading">{translateKey("tradeItemHierarchy", prefix) + " " + this.props.product.gtin}</h2>
                        </Grid>
                    </Grid>
                    <p/>
                    {
                    !this.state.isHierarchyLoadingComplete ?  <Oval type="TailSpin" color={palette.secondary.main} secondaryColor={palette.secondary.light} height={50} width={50} /> :
                    this.state.hierarchyData.length > 0 && 'gtin' in this.state.hierarchyTree ?
                    <div>
                        <div style={{ marginTop: '-1rem', marginBottom: '0.5rem', display: 'flex', alignItems: 'left', marginRight: '20px', color: palette.secondary.main, fontSize: 'large', width: '40%'}}>
                            <div>
                                <Tooltip title={translateKey('toggleHierarchy', prefix)}>
                                    {this.state.isHierarchyOpen ? 
                                        <FolderOpenIcon cursor="pointer" onClick={this.toggleHierarchy} id="trade-item-folder-open-icon"/> : 
                                        <FolderIcon cursor="pointer" onClick={this.toggleHierarchy} id="trade-item-folder-icon"/>
                                    }
                                </Tooltip>
                            </div>
                            <div style={{paddingLeft: "1rem"}}>
                                {this.state.isTopToBottom === "true"?
                                    <Tooltip title="Bottom to Top">
                                        <ArrowUpwardRoundedIcon cursor="pointer" onClick={this.toggleHierarchyTree} id="inverse-tree-icon" />
                                    </Tooltip>
                                    :
                                    <Tooltip title="Top to Bottom">
                                        <ArrowDownwardRoundedIcon cursor="pointer" onClick={this.toggleHierarchyTree} id="inverse-tree-icon" />
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <Table>
                            <thead fontSize='large' fontWeight='bold'>
                            <tr >       
                                <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} } id="trade-item-hierarchy-gtin-heading">GTIN</th>
                                <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} } id="trade-item-hierarchy-packaging-unit-heading">{translateKey("tradeItemUnitDescriptorCode", prefix)}</th>
                                <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} } id="trade-item-hierarchy-pallet-heading">{translateKey("nonGtinLogisticsInformation", prefix)}</th>
                                <th style={{ color: palette.text.main, fontSize: 'large', fontWeight: 'bold'} } id="trade-item-hierarchy-quantity-heading">{translateKey("quantityOfChildTradeItem", prefix)}</th>
                            </tr>
                            </thead>
                            <tbody>
                                { this.state.isTopToBottom === "true" ?
                                <Folder 
                                    key={this.state.hierarchyTree.gtin} 
                                    rootItem={this.props.product} 
                                    item={this.state.hierarchyTree} 
                                    addToSelection={this.addToSelection} 
                                    removeFromSelection={this.removeFromSelection} 
                                    isHierarchyOpen={this.state.isHierarchyOpen} 
                                    indent={0} searchItem={this.searchItem}
                                    hierarchyTree={this.state.hierarchyTree}

                                /> :
                                    <>
                                    {this.state.hierarchyData.map((item, index) => (
                                        <FolderReverseTree
                                            key={item.gtin} 
                                            rootItem={this.props.product} 
                                            item={item} 
                                            addToSelection={this.addToSelection} 
                                            removeFromSelection={this.removeFromSelection} 
                                            isHierarchyOpen={this.state.isHierarchyOpen} 
                                            indent={0} searchItem={this.searchItem}
                                            gtin={this.props.product.gtin}
                                        />
                                    ))}
                                    </>
                                }
                            </tbody>
                        </Table>
                    </div> : <div>{translateKey("noHierarchyDataFound",prefix)}</div>
                    }
                <div className='TradeItemHierarchyModal-footer'>
                </div>
            </div>
            </Modal>
        );
    }

}

export default withTranslation()(TradeItemHierarchyModal);