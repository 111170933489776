/* eslint-disable react-hooks/exhaustive-deps */
import { saveAs } from "file-saver";
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { palette } from '../../../assets/Palette';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ExportRequestSearch from './ExportRequestSearch';
import CircularProgress from '@mui/material/CircularProgress';
import{ getCurrentLanguage } from '../../../common/helpers/Common.js';
import { getCountryNameWithCode } from '../../../common/helpers/CountryCodeHelper.js';

const ExportRequest = ({appContext}) => {
  const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)
  
	const [rows, setRows] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [rowCount, setRowCount] = useState(0);
	const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
	const [loading, setLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [gtinSearch, setGtinSearch] = useState('');
	const [glnSearch, setGlnSearch] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [cancelRecordTosterMessage, setCancelRecordTosterMessage] = useState('');
	const [targetMarketSearch, setTargetMarketSearch] = useState('');
	const [disableExcelExport, setDisableExcelExport] = useState(false);

	useEffect(() => {
		getExportRequests(true);
	}, [page, pageSize, sortModel]);

	const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showCancelButton: true, 
      showLoader: false
    }));
  };

	const getExportRequests = async (useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

		try {
      let params = {
        gln: glnSearch,
        gtin: gtinSearch,
        targetMarket: targetMarketSearch,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        pageNumber: page + 1,
      }
     
      const response = await appContext.getExportRequests(useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
    }
	}

  const cancelExportRequest = async (exportRequestId) => {
    displayCancelButton(exportRequestId, false)
    await appContext.deleteExportRequest(exportRequestId)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            setSnackBarOpen(true)
            setCancelRecordTosterMessage(`${exportRequestId} - Cancelled successfully`)
            updateStatus(exportRequestId, 'cancelled')
          });
        } else {
          res.json().then(res => {
            console.log(res);
          })
        }
      });
  }

  const getExportRequestWithFilter = () => {
    setPage(0)
    getExportRequests(true)
  }

  const handleOnChange = (event) => {
    let fieldName = event.target.name;
    let value = event.target.value;

    switch (fieldName) {
      case 'gtinSearch':
        setGtinSearch(value);
        break;
      case 'glnSearch':
        setGlnSearch(value);
        break;
      case 'targetMarketSearch':
        setTargetMarketSearch(value);
        break;
      default:
        break;
    }
  }

  const updateStatus = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, status: status } : record
    )
    setRows(data)
  }

  const displayCancelButton = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, showCancelButton: status } : record
    )
    setRows(data)
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false)
  };

  const exportToExcel = async () => {
   try {
    setDisableExcelExport(true)
    let fileName = ''
    appContext.exportRequestExcelExport()
      .then(res => {
        let contentDisposition = res.headers.get('content-disposition').split(';')[1]
        fileName = contentDisposition.split('=')[1]
        return res.blob();
      }).then(blob => {
        if (blob !== undefined) {
          if (blob instanceof Blob) {
            saveAs(blob, fileName);
          } else if ('responseDescription' in blob) {
            alert(blob['responseDescription'])
          }
        }
        setDisableExcelExport(false)
      }).catch(err => console.error(err));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  
	const columns = [
    { field: 'gtin', headerName: 'GTIN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { field: 'gln', headerName: 'GLN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'target_market',
      headerName: 'Target Market',
      flex: 0.10,
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{getCountryNameWithCode(params.row.target_market, language)}</span>
      )
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 0.10, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => {
        if(params.row.status === "with_errors") {
          return <Tooltip title={params.row.error_message}>
            <span style={{ color: "red", fontWeight: "bold" }}>{params.row.status}</span>
          </Tooltip> 
        } else {
          return <span>{params.row.status}</span>
        }
      }
    },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.10, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.10, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
     {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        params.row.status !== 'cancelled' && ( params.row.showCancelButton ?
          <Tooltip title="Cancel">
              <IconButton
                  color="primary"
                  onClick={() => cancelExportRequest(params.row.id)}
                  size="large">
                  <DeleteIcon style={{ color: red[500], cursor: 'pointer' }} />
              </IconButton>
          </Tooltip>
          :
          <Tooltip title="Cancel">
            <IconButton size="large">
              <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
            </IconButton>
          </Tooltip>
        )
      ),
    },
  ];

	return (
    <div style={{ height: 650, width: '100%'}}>
      <Snackbar open={snackBarOpen} autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={handleSnackBarClose} severity="success">
          {cancelRecordTosterMessage}
        </Alert>
      </Snackbar>
      <ExportRequestSearch
        isSearching={isSearching} 
        gtinSearch={gtinSearch}
        glnSearch={glnSearch}
        targetMarketSearch={targetMarketSearch}
        handleOnChange={handleOnChange}
        submitFunction={getExportRequestWithFilter}
        exportToExcelFile={exportToExcel}
        disableExcelExportButton={disableExcelExport}
      />
      <div style={{paddingTop: "2rem"}}></div>
      <DataGrid
        disableColumnSelector
        loading={loading}
        rows={rows}
        columns={columns}
        paginationModel={{ page, pageSize }}
        pagination
        onPaginationModelChange={(newPaginationModel) => {
          setPage(newPaginationModel.page);
          setPageSize(newPaginationModel.pageSize);
        }}
        paginationMode="server"
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            width: '98% !important',
          },
          '& .MuiCircularProgress-root': {
            color: palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
      />
    </div>
  );
	
};

export default ExportRequest;