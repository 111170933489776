import i18next from 'i18next';
import { saveAs } from "file-saver";
import FileUpload from './FileUpload';
import Grid from '@mui/material/Grid2';
import Input from '@mui/material/Input';
import React, { useState } from 'react';
import { red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { palette } from '../../../assets/Palette';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import Autocomplete from '@mui/material/Autocomplete';
import { FormHelperText, FormControl } from "@mui/material";
import { ReactComponent as DataImportIcon } from '../../../database-import.svg';
import targetMarkets from './TargetMarkets';

const ReImportForm = ({appContext, handleSnackBarOpen}) => {
	const item = {
		gtin: '',
		gln: '',
		target_market: null,
		errors: ''
	}

	const [items, setItems] = useState([item]);

	const handleFormChange = (index) => (event, newValue) => {
		let name, value

		if (event.target.tagName === "LI") {
			name = "target_market";
			value = newValue;
		} else {
			name = event.target.name;
			value = event.target.value;
			if (!/^\d*$/.test(value)) return;
		}

    setItems((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, [name]: value, errors: { ...row.errors, [name]: "" } } : row
      )
    );
  };

	const handleRemoveSpecificRow = (i) => (event) => {
		const itemDetails = [...items]
		itemDetails.splice(i, 1)

		setItems(itemDetails)
	};

	const validateItem = (item) => {
    let errors = {};
		const gtin = String(item.gtin)
		const gln = String(item.gln)
		const target_market = item.target_market

    if (!gtin.trim()) {
			errors.gtin = "can't be empty.";
		} else if (gtin.length < 8 || gtin.length > 14) {
      errors.gtin = i18next.t("markantReImportModal.gtinErrorMessage");
    }

		if (!gln.trim()) {
			errors.gln = "can't be empty.";
		} else if (gln.length !== 13) {
      errors.gln = i18next.t("markantReImportModal.glnErrorMessage");
    }

		if (target_market === null) {
      errors.target_market = "can't be empty.";
    }
    return errors;
  };

	const populateErrorMessages = (items) => {
    const validatedItems = items.map((item) => {
      return {
        ...item,
        errors: validateItem(item),
        touched: { gtin: true, gln: true, target_market: true }
      };
    });

    setItems(validatedItems);
  };

	const importData = () => (event) => {
		if (isFormValid()) {
			appContext.importData(items)
				.then(res => {
					if (res.status === 200) {
						const snackBarMessage = i18next.t("markantReImportModal.importToExportRequest")
						handleSnackBarOpen(snackBarMessage)
						setItems([item])
					} else if (res.status === 202){
						const snackBarMessage = i18next.t("markantReImportModal.noExportRequestsAdded")
						handleSnackBarOpen(snackBarMessage, 'error')
					}
				})
				.catch(err => console.error(err));
		} else {
			populateErrorMessages(items)
			const snackBarMessage = i18next.t("markantReImportModal.allMandatoryFieldsRequired")
			handleSnackBarOpen(snackBarMessage, 'error');
			return false
		}
	}

	const downloadSampleTemplate = () => (event) => {
		try {
			let fileName = ''

			appContext.downloadImportTemplate()
				.then(res => {
						let contentDisposition = res.headers.get('content-disposition').split(';')[1]
						fileName = contentDisposition.split('=')[1]
						return res.blob();
				}).then(blob => {
						if (blob !== undefined) {
								if (blob instanceof Blob) {
										saveAs(blob, fileName);
								} else if ('responseDescription' in blob) {
										alert(blob['responseDescription'])
								}
						}
				}).catch(err => console.error(err));
		} catch (error) {
      console.error('Error fetching data:', error);
		}
	}

	// Validate all rows and return boolean
  const isFormValid = () => {
    return items.every((item) => Object.keys(validateItem(item)).length === 0);
  };

	const handleAddItem = () => {
		const updatedItems = items.map((item) => ({
			...item,
			errors: validateItem(item),
		}));
		const hasErrors = updatedItems.some((item) => Object.keys(item.errors).length > 0);

		if (hasErrors) {
      setItems(updatedItems);
    } else {
      setItems([...updatedItems, item]);
    }
	};

	return (
		<Grid container style={{display: 'flex', flexWrap: 'nowrap' }}>
			<Grid size={8}>
				<div style={{marginTop: '5px', fontSize: "15px"}}>
					<Grid container style={{display: 'flex', flexWrap: 'nowrap' }}>
						<Grid size={6} style={{paddingLeft: "8px"}}>&nbsp;</Grid>
						<Grid size={5} style={{marginLeft: "30px"}}>
							<Grid container justifyContent="flex-end">
								<Grid size={4} style={{paddingLeft: "15px", marginTop: "-4px", width: "auto"}}>
									<Tooltip title={i18next.t('markantReImportModal.excelDownload')} aria-label={i18next.t('markantReImportModal.excelDownload')}>
										<IconButton
											disabled={false}
											color="primary"
											onClick={downloadSampleTemplate()}
											size="large"
											id="data-request-excel-file-download-icon">
												<GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
											</IconButton>
									</Tooltip>
								</Grid>
								<Grid size={4}  style={{marginTop: "-4px", width: "auto"}}>
									<FileUpload appContext={appContext} updatedData={setItems} snackBarOpen={handleSnackBarOpen}/>
								</Grid>
								<Grid size={4} style={{marginTop: "-7px", width: "auto"}}>
									<Tooltip title={i18next.t('markantReImportModal.addToExportList')} aria-label={i18next.t('markantReImportModal.addToExportList')}>
										<IconButton
											color="primary"
											onClick={importData()}
											size="large"
											id="data-request-import-to-export-request-icon">
												<DataImportIcon width={27} height={27} color="primary" style={{cursor: "pointer", marginRight: '8%', fontSize: 30}}/>
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				<div style={{marginTop: '16px'}}>
					<Grid container spacing={1} style={{fontSize: "15px", fontWeight: 'bold'}}>
						<Grid size={4} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
							<div key={'div'} 
								style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}
								id="data-re-import-form-gln-business-partner-heading">
								{i18next.t('markantReImportModal.gtinBasisProductPiece')}
							</div>
						</Grid>
						<Grid size={4} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
							<div key={'div'} 
								style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
								id="data-re-import-form-gtin-basis-product-piece-heading">
								{i18next.t('markantReImportModal.glnBusinessPartner')}
							</div>
						</Grid>
						<Grid size={3} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
							<div key={'div'} 
								style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
								id="data-re-import-form-target-market-heading">
								{i18next.t('markantReImportModal.targetMarket')}
							</div>
						</Grid>
						<Grid size={1}></Grid>
					</Grid>
				</div>
				{items.map((value, i) => {
					return <div style={{marginTop: '8px'}} key={i}>
						<Grid container spacing={1} style={{display: 'flex', flexWrap: 'nowrap' }}>
							<Grid size={4} style={{ marginRight: '8px'}}>
								<div key={i}>
									<FormControl fullWidth error={!!items[i].errors.gtin}>
										<Input name="gtin" id={"data-re-import-form-gtin-input"+i} fullWidth
											value={items[i].gtin}
											onChange={handleFormChange(i)}
											error={!!items[i].errors.gtin}
											helperText={items[i].errors.gtin}
											variant='outlined'
										/>
										{items[i].errors.gtin && (
											<FormHelperText id="gtin-error" sx={{ marginLeft: 0 }}>{items[i].errors.gtin}</FormHelperText>
										)}
									</FormControl>
								</div>
							</Grid>
							<Grid size={4} style={{ marginRight: '8px'}}>
								<div key={i}>
									<FormControl fullWidth error={!!items[i].errors.gln}>
										<Input name="gln" id={"data-re-import-form-gln-input"+i} fullWidth
											value={items[i].gln}
											onChange={handleFormChange(i)}
											error={!!items[i].errors.gln}
											helperText={items[i].errors.gln}
										/>
										{items[i].errors.gln && (
											<FormHelperText id="gln-error" sx={{ marginLeft: 0 }}>{items[i].errors.gln}</FormHelperText>
										)}
									</FormControl>
								</div>
							</Grid>
							<Grid size={3} style={{ marginRight: '8px'}}>
								<div key={i}>
									<FormControl fullWidth error={!!items[i].errors.target_market}>
										<Autocomplete
											id={"data-re-import-form-target-market-input"+i}
											size="small"
											value={items[i].target_market}
											onChange={handleFormChange(i)}
											options={targetMarkets}
											getOptionLabel={(option) => option.value}
											isOptionEqualToValue={(option, value) => option.code === value.code}
											error={!!items[i].errors.target_market}
											helperText={items[i].errors.target_market}
											renderInput={(params) => (
													<TextField name="target_market" {...params} variant="standard"  />
											)}
										/>
										{items[i].errors.target_market && (
											<FormHelperText id="target-market-error" sx={{ marginLeft: 0 }}>{items[i].errors.target_market}</FormHelperText>
										)}
									</FormControl>
								</div>
							</Grid>
							<div key={i} size={1} style={{ paddingTop:'14px' }}>
								<DeleteIcon
									style={{ color: red[500], cursor: 'pointer' }}
									onClick={handleRemoveSpecificRow(i)}
									id={"data-re-import-form-delete-item-button"+i}
								/>
							</div>
						</Grid>
					</div>
				})}
				<div style={{marginTop: '16px'}}>
					<button onClick={handleAddItem} className="btn btn-sm" id="data-re-import-form-add-item-button">
						{i18next.t('markantReImportModal.addItem')}
					</button>
				</div>
			</Grid>
		</Grid>
	)
};

export default ReImportForm;