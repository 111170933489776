
import React from "react";

export function traverseJson(json) {
    let list = []
    if (json !== undefined && json !== null) {
        Object.keys(json).map(function (key) {
            if (json[key] instanceof Array && json[key].length === 1 && "0" in json[key]) {
                if (typeof json[key][0] === 'object') {
                    if ('_' in json[key][0]) {
                        list.push(<li key={key + '_'}>{key + ': '} <b>{json[key][0]['_']}</b></li>)
                    } else {
                        list.push(<li key={key}>{key}</li>)
                    }
                    list.push(<ul key={'nested' + key} className='nestedList'>{traverseJson(json[key][0])}</ul>)
                } else {
                    list.push(<li key={key}>{key + ': '} <b>{json[key]}</b></li>)
                }
            } else if (json[key] instanceof Array && json[key].length > 1) {
                for (let i = 0; i < json[key].length; i++) {
                    if (typeof json[key][i] === 'string') {
                        list.push(<li key={key + i}>{key + ': '} <b>{json[key][i]}</b></li>)
                        continue
                    } else if (typeof json[key][i] === 'object' && '_' in json[key][i]) {
                        list.push(<li key={key + i + '_'}>{key + ': '} <b>{json[key][i]['_']}</b></li>)
                    } else {
                        list.push(<li key={key + i}>{key}</li>)
                    }
                    list.push(<ul key={'nested' + key + i} className='nestedList'>{traverseJson(json[key][i])}</ul>)
                }
            } else if (typeof json[key] === 'string') {
                if ('_' !== key && !key.includes('xmlns') && !key.includes('xsi')) {
                    list.push(<li key={key}>{key + ': '} <b>{json[key]}</b></li>)
                }
            } else if (typeof json[key] === 'object' && "$" === key) {
                list.push(traverseJson(json[key]))
            }
            return list
        });
    }  
    return list;
}

export function deepGet(obj, path) {
  return path.split('.').reduce((acc, key) => {
    if (acc === undefined) return undefined;

    // Handle array indexing with numeric indices like ".0"
    const arrayMatch = key.match(/^(\w+)\.(\d+)$/);
    
    if (arrayMatch) {
      const arrayKey = arrayMatch[1]; // e.g., "persons"
      const index = parseInt(arrayMatch[2], 10); // e.g., 0
      return Array.isArray(acc[arrayKey]) ? acc[arrayKey][index] : undefined;
    }

    return acc[key]; // Traverse to the next level
  }, obj);
}