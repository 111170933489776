/* eslint-disable react-hooks/exhaustive-deps */
import { saveAs } from "file-saver";
import Alert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ExportListSearch from './ExportListSearch';
import { palette } from '../../../assets/Palette';
import React, { useState, useEffect } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from '@mui/material/CircularProgress';
import{ getCurrentLanguage } from '../../../common/helpers/Common.js';
import { getCountryNameWithCode } from '../../../common/helpers/CountryCodeHelper.js';

const ExportList = ({apiContext, glnId, onUpdate}) => {
  const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

	const [rows, setRows] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [rowCount, setRowCount] = useState(0);
	const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [gtinSearch, setGtinSearch] = useState('');
	const [glnSearch, setGlnSearch] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [targetMarketSearch, setTargetMarketSearch] = useState('');
  const [disableExcelExport, setDisableExcelExport] = useState(false);

  const [messageSeverity, setMessageSeverity] = useState(false);
  const [tosterMessage, setTosterMessage] = useState('');
  const [showGrid, setShowGrid] = useState(true);

  useEffect(() => {
    getExportList(glnId, true)
	}, [page, pageSize, sortModel]);


  useEffect(() => {
    if (glnId) {
      setShowGrid(true)
      setInitialLoading(true)
      getExportList(glnId, true)
    } else {
      setShowGrid(false)
      setRows([]);
    }
	}, [glnId]);


	const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showResetButton: true, 
      showLoader: false
    }));
  };

	const getExportList = async (glnId, useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

		try {
      let params = {
        gln: glnSearch,
        gtin: gtinSearch,
        targetMarket: targetMarketSearch,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        pageNumber: page + 1,
      }
      
      const response = await apiContext.getExportList(glnId, useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
      setInitialLoading(false)
    }
	}

  const getImportListWithFilter = () => {
    setPage(0)
    getExportList(glnId, true)
  }

  const handleOnChange = (event) => {
    let fieldName = event.target.name;
    let value = event.target.value;

    switch (fieldName) {
      case 'gtinSearch':
        setGtinSearch(value);
        break;
      case 'glnSearch':
        setGlnSearch(value);
        break;
      case 'targetMarketSearch':
        setTargetMarketSearch(value);
        break;
      default:
        break;
    }
  }

  const updateStatus = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, status: status } : record
    )
    setRows(data)
  }

  const resetExportList = async (exportListId) => {
    displayResetButton(exportListId, false)
    await apiContext.resetExportList(exportListId)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            setSnackBarOpen(true)

            setMessageSeverity('success')
            setTosterMessage(`${exportListId} - reset successfull`)
            updateStatus(exportListId, 'ready_for_export')
            onUpdate({id: glnId, status: 'initialize'})
          });
        } else {
          res.json().then(res => {
            console.log(res);
          })
        }
      });
  }

  const displayResetButton = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, showResetButton: status } : record
    )
    setRows(data)
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false)
  };

  const exportToExcel = async () => {
    try {
      setDisableExcelExport(true)
      let fileName = ''
      apiContext.exportListExcelExport(glnId)
      .then(res => {
        let contentDisposition = res.headers.get('content-disposition').split(';')[1]
        fileName = contentDisposition.split('=')[1]
        return res.blob();
      }).then(blob => {
        if (blob !== undefined) {
          if (blob instanceof Blob) {
            saveAs(blob, fileName);
          } else if ('responseDescription' in blob) {
            alert(blob['responseDescription'])
          }
        }
        setDisableExcelExport(false)
      }).catch(err => console.error(err));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const downloadGeneratdCin = (export_list_id) => (event) => {
    try {
      let fileName = ''

      apiContext.downloadGeneratdCin(export_list_id)
        .then(res => {
          let contentDisposition = res.headers.get('content-disposition').split(';')[1]
          fileName = contentDisposition.split('=')[1]
          
          return res.blob()
        }).then(blob => {
          if (blob !== undefined) {
            if (blob instanceof Blob) {
                saveAs(blob, fileName);
            } else if ('responseDescription' in blob) {
                alert(blob['responseDescription'])
            }
          }
        }).catch(err => console.error(err));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

	const columns = [
    { field: 'gtin', headerName: 'GTIN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { field: 'gln', headerName: 'GLN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'target_market',
      headerName: 'Target Market',
      flex: 0.10,
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{getCountryNameWithCode(params.row.target_market, language)}</span>
      )
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 0.10, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => {
        if(params.row.status === "with_errors") {
          return <Tooltip title={params.row.error_message}>
            <span style={{ color: "red", fontWeight: "bold" }}>Export fail</span>
          </Tooltip> 
        } else if (params.row.status === "exported") {
          return <span>Export is successful</span>
        } else {
          return <span>Ready for export</span>
        }
      }
    },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.10, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.10, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        params.row.status !== 'ready_for_export' && ( params.row.showResetButton ?
          <>
          <Tooltip title="Reset">
              <IconButton
                  color="primary"
                  onClick={() => resetExportList(params.row.id)}
                  size="large">
                  <RestartAltIcon style={{cursor: 'pointer' }} />
              </IconButton>
          </Tooltip>
          { params.row.status === "exported" &&
          <Tooltip title="Download" aria-label="Download">
            <IconButton
              disabled={false}
              color="primary"
              onClick={downloadGeneratdCin(params.row.id)}
              size="large"
              id="data-request-excel-file-download-icon">
                <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
              </IconButton>
          </Tooltip> } </>
          :
          <Tooltip title="Reset">
            <IconButton size="large">
              <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
            </IconButton>
          </Tooltip>
        )
      ),
    },
  ];

	return (
    
    <div style={{ height: 650, width: '100%'}}>
      <Snackbar open={snackBarOpen} autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={handleSnackBarClose} severity={messageSeverity}>
          {tosterMessage}
        </Alert>
      </Snackbar>
      {glnId && showGrid && (
        <>
          { initialLoading ? (
              <div style={{paddingTop: "2rem"}}>
                <CircularProgress size={40} style={{ color: palette.secondary.main }} thickness={3} />
              </div>
            ) : (
            <>
              <div style={{paddingTop: "1.5rem"}}><h4 style={{fontWeight: "600"}}>Subscriptions of the selected order</h4></div>
              <ExportListSearch
                isSearching={isSearching} 
                gtinSearch={gtinSearch}
                glnSearch={glnSearch}
                targetMarketSearch={targetMarketSearch}
                handleOnChange={handleOnChange}
                submitFunction={getImportListWithFilter}
                exportToExcelFile={exportToExcel}
                disableExcelExportButton={disableExcelExport}
              />
              <div style={{paddingTop: "1rem"}}></div>
              <DataGrid
                disableColumnSelector
                loading={loading}
                rows={rows}
                columns={columns}
                paginationModel={{ page, pageSize }}
                pagination
                onPaginationModelChange={(newPaginationModel) => {
                  setPage(newPaginationModel.page);
                  setPageSize(newPaginationModel.pageSize);
                }}
                paginationMode="server"
                rowCount={rowCount}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                pageSizeOptions={[5, 10, 25]}
                disableRowSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    width: '98% !important',
                  },
                  '& .MuiCircularProgress-root': {
                    color: palette.secondary.main,
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                }}
                autoHeight
              />
            </>)
          }
        </>
      )}
    </div>
  );
};

export default ExportList;