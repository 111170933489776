import React, { useState } from 'react';
import {Menu, MenuItem, Button } from '@mui/material';
import { palette } from '../assets/Palette';
import i18n from '../i18n';
import '../assets/products.css';

export const DropdownMenu = (
    { 
    style={},
    listProp = [],
    translationKey = null, 
    defaultValue, 
    id,
    onSelect }) => {

    const defaultStyle={color: palette.text.ui_text, backgroundColor: null, borderColor: palette.warning.main, outline: 'none'}
    style = { ...defaultStyle, ...style };

    const [selected, setSelected] = useState(defaultValue || listProp[0])
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
        
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleMenuItemClick = (item) => {
        setSelected(item);
        onSelect(item);
        handleClose();
    };

    return (
        <>
        <Button
        variant="text"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={style}
      >
        {translationKey ? i18n.t(translationKey+'.'+selected) : selected}
      </Button>
        <Menu
        id={id || 'simple-menu'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        {(Array.isArray(listProp) ? listProp : []).map((item, index) => {
            return (
            <MenuItem
                key={index}
                selected={item === selected}
                style={{textTransform: 'uppercase'}}
                onClick={() => handleMenuItemClick(item)}
            >
                {translationKey ? i18n.t(translationKey +'.'+item) : item}
            </MenuItem>
            );
        })}
        </Menu>
        </>
    );

}
