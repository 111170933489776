/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import i18next from 'i18next';
import Grid from '@mui/material/Grid2';
import { LoadingButton } from "@mui/lab";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const ExportListSearch = ({isSearching, gtinSearch, glnSearch, targetMarketSearch, handleOnChange, submitFunction, exportToExcelFile, disableExcelExportButton}) => {

	return (
		<Grid container direction="row">
			<Grid item xs="2">
				<TextField id="init-list-tab-gtin-search"
					name="gtinSearch"
					style={{width: "90%"}}
					label={i18next.t('aldiPlmService.gtin')}
					value={gtinSearch}
					onChange={handleOnChange} variant="outlined" size="small"
					disabled={isSearching}
				/>
			</Grid>
			<Grid item xs="2">
				<TextField id="init-list-tab-gln-search"
					name="glnSearch"
					style={{width: "90%"}}
					label={i18next.t('aldiPlmService.gln')}
					value={glnSearch}
					onChange={handleOnChange} variant="outlined" size="small"
					disabled={isSearching}
				/>
			</Grid>
				
			<Grid item xs="2">
				<TextField id="init-list-tab-target-market-search"
					name="targetMarketSearch"
					style={{width: "90%"}}
					label={i18next.t('aldiPlmService.targetMarket')}
					value={targetMarketSearch}
					onChange={handleOnChange} variant="outlined" size="small"
					disabled={isSearching}
				/>
			</Grid>
			<Grid item>
				<Button variant="contained"
					disabled={isSearching}
					color="primary"
					style={{ height: '40px' }}
					id="article-pass-tab-search-button"
					onClick={submitFunction}
				>
					{i18next.t('gdsnSubscriptionModal.search')}
				</Button>
			</Grid>
			<Grid item>
				<Tooltip title={i18next.t('markantDataRequestModal.refresh')} aria-label={i18next.t('markantDataRequestModal.refresh')}>
					<IconButton
						disabled={isSearching}
						onClick={submitFunction}
						style={{marginTop: -5, marginLeft: 5}}
						size="large"
						id="article-pass-tab-refresh-button">    
							<RefreshIcon color="primary" style={{cursor: "pointer"}}/>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid item sx={{ ml: "auto" }}>
				<LoadingButton variant="contained" 
					loading={disableExcelExportButton}
					color="primary"
					style={{ height: '40px' }}
					id="article-pass-tab-search-button"
					onClick={exportToExcelFile}
					>
					Export to Excel
				</LoadingButton>
			</Grid>
		</Grid>
  );
};

export default ExportListSearch;