import React, { useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import Modal from 'react-modal';
import Grid from '@mui/material/Grid2';
import i18n from '../../i18n';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Tooltip, TextField, InputAdornment, Snackbar, Alert} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { palette } from '../../assets/Palette';
import { DropdownMenu } from '../Dropdown.jsx';
import ChangePasswordModal from './ChangePasswordModal';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const EditUserModal = ({user, roleRights, customerContext, onSubmit, onClose}) => {

    const editUserModalStyle = {
        content: {
            top: '17%',
            left: '50%',
            right: '72%',
            marginRight: '-70%',
            transform: 'translate(-50%, -17%)',
            maxWidth: '1024px',
            overlay: { zIndex: 1000 }
        }
    };


    const createPassword = () => {
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const digits = '0123456789';
        const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
        const characters = upperCase + lowerCase + digits + specialChars;
        const passwordLength = 14;
        let password = Array.from({ length: passwordLength }, () => 
            characters[Math.floor(Math.random() * characters.length)]
        ).join('');

        const hasUpper = /(?=.*?[A-Z])/.test(password);
        const hasLower = /(?=.*?[a-z])/.test(password);
        const hasDigits = /(?=.*?[0-9])/.test(password);
        const hasSpecialChars = /(?=.*?[\W_])/.test(password);

        if(!hasUpper) password += upperCase[Math.floor(Math.random() * upperCase.length)]
        if(!hasLower) password += lowerCase[Math.floor(Math.random() * lowerCase.length)]
        if(!hasDigits) password += digits[Math.floor(Math.random() * digits.length)]
        if(!hasSpecialChars) password += specialChars[Math.floor(Math.random() * specialChars.length)]

        return password;
    }

    const [newUsername, setNewUsername] = useState(null);
    const [userRole, setUserRole] = useState(user?.role ?? 'standard_read_only');
    const [password, setPassword] = useState(createPassword());
    const [showPassword, setShowPassword] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);
    const [isUsernameError, setIsUsernameError] = useState(false)
    const [isPasswordError, setIsPasswordError] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const confirmDeletion = () => {
        return(
        <MuiAlert
        id='delete-user-alert-window'
        onClose={() => setIsConfirmDeletePopupOpen(false)}
        severity="warning"
        color="info"
        action={
            <>
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setIsConfirmDeletePopupOpen(false)}
            />
            </>
        }
        >
        <h2 id='delete-user-heading-1' >{i18n.t('userManagement.sureDelete')}</h2>
        <h2 id='delete-user-heading-2' >{i18n.t('userManagement.delete') + ': ' + user.name}</h2>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <Button
            id='delete-user-yes-button'
            variant="contained"
            style={{backgroundColor: palette.primary.main}}
            onClick={() => {
                deleteUser(user.name);
                setIsConfirmDeletePopupOpen(false);
            }}
            >{i18n.t('userManagement.yes')}
            </Button>
            
            <Button
            id='delete-user-no-button'
            variant="contained"
            style={{backgroundColor: palette.primary.main}}
            onClick={() => setIsConfirmDeletePopupOpen(false)}
            >
            {i18n.t('userManagement.no')}
            </Button>
        </div>
        </MuiAlert>
        )
    }

    const toggleChangePasswordModal = () => {
        setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
    }

    const handleUsernameInput = (e) => {
        setNewUsername(e.target.value)
        setIsUsernameError(!isValidUsername(e.target.value))
    }

    const isValidPassword = (password) => {
        if(password.length < 12) {
            return false
        } else {
            return /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W_])/.test(password);
        }
    }

    const handlePasswordInput = (e) => {
        setPassword(e.target.value)
    }

    const selectUserRole = (role) => {
        setUserRole(role)
    }

    const handleDeleteClick = () => {
        setIsConfirmDeletePopupOpen(true)
    }

    const handleSubmitClick = async () => {
        let isValid = true
        if(user === null){
            if(newUsername === null || newUsername.trim().length === 0){
                setIsUsernameError(true);
                isValid = false
            }
            if(!isValidPassword(password)){
                setIsPasswordError(true);
                isValid = false
            }
            if(isValid){
                isValid = await createUser()
            }
        } else {
            if(newUsername !== null && newUsername.trim().length === 0){
                isValid = false
                setIsUsernameError(true);
            }
            if(isValid){
                isValid = await updateUser()
            }
        }
        if(isValid){
            setNewUsername(null);
            setPassword(null);
            onSubmit();
        }
    }

    const createUser = async () => {
        const data = {
            'username': newUsername,
            'password': password,
            'userRole': userRole
        }
        try {
            const res = await customerContext.userManagementApiService.createUser(data);
            if(res.status === 409){
                alert(i18n.t('userManagement.userAlreadyExists'))
                setIsUsernameError(true)
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
        setPassword(null);
        return true;
    }

    const updateUser = async () => {
        let data = {OldUsername: user.name, username: user.name}
        if(Boolean(newUsername) && (newUsername !== user.name)) data = {...data, username: newUsername}
        if(userRole !== user.role) data = {...data, userRole: userRole}
        if(JSON.stringify(data) !== JSON.stringify({OldUsername: user.name, username: user.name})){
            try {
                const res = await customerContext.userManagementApiService.updateUser(data);
                if(res.status === 409){
                    alert(i18n.t('userManagement.userAlreadyExists'))
                    setIsUsernameError(true)
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        }
        return true;
    }

    const deleteUser = async () => {
        try {
            const res = await customerContext.userManagementApiService.deleteUser(user.name);
            setIsConfirmDeletePopupOpen(false)
        } catch (error) {
            console.error(error);
        }
        onClose()
    }

    const isPasswordChangable = () => {
        return (Boolean(user?.name) && newUsername === null || (newUsername === user?.name)) || (!Boolean(user?.name) && isValidUsername(newUsername))
    }
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(password)
          .then(() => {
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("Error copying text: ", error);
          });
    };
    
      const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const isValidUsername = (username) => {
        if (username === null) return false;
                
        const isEmpty = username => !username || username.trim().length === 0;
        const regex = /^[A-Za-z0-9_-]+$/;
        const length = username.length;
        
        if (isEmpty(username)) return false; 
        if (length < 3 || length > 20) return false;
        if (username.trim() !== username || username.trim().length !== length) return false;
        if (!regex.test(username)) return false;
        return true;
    }

    const renderSnackbar = () => {
       return (
        <>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {i18n.t('usermanagement.copiedToClipboard')}
        </Alert>
      </Snackbar>
          </>
        )
    }
    
    return(
        <Modal
        isOpen={true}
        style={editUserModalStyle}
        ariaHideApp={false}
        >
        {isChangePasswordModalOpen && <ChangePasswordModal toggleChangePassword={toggleChangePasswordModal} toggleChangePasswordModal={isChangePasswordModalOpen} username={user.name} isUserManagement={true}/>}
        {isConfirmDeletePopupOpen && confirmDeletion()}
        {renderSnackbar()}
        <Grid>
        <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
            <Tooltip title={i18n.t('dashboard.close')}>
                <IconButton
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => { onClose()}}
                    style={{ outline: 'none' }}
                ><CloseRoundedIcon  style={{ color: palette.warning.close }}/>
                </IconButton>
            </Tooltip>
        </div>
        {user ? <h2 id='um-edit-user-heading' style={{color: palette.text.dark}}>{i18n.t('userManagement.edit') + ' ' + user.name}</h2> : 
        <h2 id='um-new-user-heading' style={{color: palette.text.dark}}>{i18n.t('userManagement.createNew')}</h2>}
        </Grid>
        <Grid>
        <TableContainer>
          <Table className="edit_user_table">
            <TableHead>
              <TableRow>
                <TableCell id='um-label-name' style={{color: palette.text.ui_text}}>{i18n.t('userManagement.name')}</TableCell>
                <TableCell style={{color: palette.text.ui_text, padding: "24px"}}><TextField id='um-textbox-name' 
                InputProps={{
                    disableUnderline: Boolean(user) && !isUsernameError,
                    style: { color: palette.text.dark, borderColor: palette.secondary.light }
                  }}
                error={isUsernameError}
                placeholder={isUsernameError ? i18n.t('userManagement.usernameRequired') : ''}
                defaultValue={user ? user.name : ''}
                disabled={user !== null && localStorage.getItem('user') === user.name}
                onChange={handleUsernameInput}
                ></TextField></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell id='um-label-password' style={{color: palette.text.ui_text}}>{i18n.t('userManagement.password')}</TableCell>
                {user ?
                <TableCell>          
                    <Button
                    id='um-change-password-button'
                    variant="text"
                    disabled={!isPasswordChangable()} 
                    style={{color: palette.text.dark, outline: 'none', marginLeft: '8px'}}
                    onClick={() => { toggleChangePasswordModal() }}
                >{i18n.t('changePasswordModal.changePassword')}</Button>
                </TableCell> : <TableCell style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: palette.text.ui_text, padding: "24px"}}>
                    <TextField
                        id='um-textbox-password'
                        type={showPassword ? 'text' : 'password'} 
                        value={password}
                        onChange={handlePasswordInput}
                        error={isPasswordError}
                        placeholder={isPasswordError ? i18n.t('userManagement.passwordRequired') : ''}
                        InputProps={{
                            endAdornment: (
                                <>
                                <InputAdornment position="end">
                                <Tooltip title={i18n.t('userManagement.showPassword')}> 
                                    <IconButton
                                        id='um-eye-button'
                                        style={{outline: 'none'}}
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >{showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                                <InputAdornment position="end">
                                <Tooltip title={i18n.t('userManagement.copyPassword')}>
                                    <IconButton
                                        id='um-copy-button'
                                        style={{outline: 'none'}}
                                        onClick={() => handleCopyToClipboard()}
                                        edge="end"
                                        ><ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                                </>
                            ),
                        }}
                    />
                    <MuiAlert
                        id='um-alert-copy-password'
                        style={{marginLeft: '4%', height: '40px'}}
                        severity="info"
                        color="warning"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            "& .MuiAlert-icon": {
                              alignSelf: "flex-start", 
                              marginTop: "-4px", 
                            },
                            "& .MuiAlert-message": {
                              display: "flex",
                              flexDirection: "column",
                            },
                          }}
                        >
                        {i18n.t('userManagement.requestCopyPassword')}
                    </MuiAlert>
                    </TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell id='um-label-role' style={{color: palette.text.ui_text}}>{i18n.t('userManagement.role')}</TableCell>
                <TableCell id='um-dropdown-button'>{
                    user === null || localStorage.getItem('user') != user.name ? 
                    <DropdownMenu
                        id='um-dropdown-role-menu'
                        style={{color: palette.text.dark, marginLeft: '8px'}}
                        listProp={Object.keys(roleRights)}
                        defaultValue={user ? i18n.t(user.role) : i18n.t(userRole)} 
                        translationKey={'userManagement'}
                        onSelect={selectUserRole}/> :
                    <Button
                        id='test-btn'
                        style={{color: palette.text.light, marginLeft: '8px'}}
                        disabled={true}
                    >{i18n.t(userRole)}</Button>
                }
                </TableCell>
            </TableRow>
            <TableRow>
            <TableCell id='um-label-rights' style={{color: palette.text.ui_text}}>{i18n.t('userManagement.rights')}</TableCell>
                <TableCell>
                <Table className="users_rights_table">
                <TableBody>
                {roleRights[userRole].map(obj => (
                    <TableRow key={obj.right}>
                <Tooltip title={obj.description}><TableCell id={'um-rights-'+obj.right} style={{ border: 'none', color: palette.text.ui_text }}>{i18n.t('userManagement.'+obj.right)}</TableCell></Tooltip>
                </TableRow>
                ))}
                </TableBody>
                </Table>
                </TableCell></TableRow>
            </TableBody>
            </Table>
        </TableContainer>
        </Grid>
        <br/>
        <Grid   style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '2%',
                marginRight: '2%'
            }}>
            <Button variant="contained" id='um-submit-button'
                onClick={ () => handleSubmitClick()}
                style={{color: palette.text.veryLight, backgroundColor: palette.primary.main, outline: 'none'}}
            >
                {i18n.t('userManagement.submit')}
            </Button>
            {user &&
            <Button variant="contained" id='um-delete-button'
                disabled={localStorage.getItem('user') == user.name}
                style={{color: palette.text.veryLight, backgroundColor: localStorage.getItem('user') != user.name ? palette.error.main : palette.text.light, outline: 'none'}}
                onClick={ () => handleDeleteClick()}
            >
                {i18n.t('userManagement.delete')}
            </Button>
            }
        </Grid>
        </Modal>
    )
}

export default EditUserModal;