import i18next from 'i18next';
import Tooltip from '@mui/material/Tooltip';
import { palette } from '../../../assets/Palette';
import IconButton from '@mui/material/IconButton';
import React, { useState, useEffect } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import targetMarkets from './TargetMarkets';

const FileUpload = ({appContext, updatedData, snackBarOpen}) => {

	const [file, setFile] = useState('');
	const [showProgress, setShowProgress] = useState(false);

	useEffect(() => {
		handleFileUpload()
	}, [file]); 

	const handleFileChange = () => (event) => {
		setFile(event.target.files[0])
	};

	const handleFileUpload = () => {
		if (!file) return;

		const formData = new FormData();
		formData.append('file', file);

		try {
			setShowProgress(true)
			appContext.reImportExcelFileUpload(formData)
			.then(res => res.json())
			.then(res => {
				if (!('error' in res)) {

					const itemList = res.records.map((item) => ({
						...item,
						target_market: targetMarkets.find(tm => String(tm.code) === String(item.target_market)) || null
					}));

					updatedData(itemList)
				} else {
					snackBarOpen("Error while uploading the file", 'error')
				}
				setShowProgress(false)
			}).catch(err => console.error(err));
		} catch (error) {
			console.error('Error uploading file:', error);
		}
	}

	return (
		<div>
			{ !showProgress ? 
			<>
				<input accept=".xlsx, .xls" style={{display: 'none'}} id="contained-button-file" type="file" onChange={handleFileChange()}/>
				<label htmlFor="contained-button-file">
					<Tooltip title={i18next.t('markantDataRequestModal.uploadExcel')} aria-label={i18next.t('markantDataRequestModal.uploadExcel')}>
						<IconButton color="primary" aria-label="upload picture" component="span" size="large" id="data-request-excel-file-upload-icon">
							<CloudUploadIcon />
						</IconButton>
					</Tooltip>
				</label>
			</> : 
				<IconButton color="primary" component="span" style={{paddingTop:"15px"}} size="large" id="data-request-excel-file-upload-icon">
					<CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
				</IconButton>
			}
		</div>
	)
}

export default FileUpload;