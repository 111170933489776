const targetMarkets = [
    {'code': '276', 'value': 'DE(276)'},
    {'code': '203', 'value': 'CZ(203)'},
    {'code': '703', 'value': 'SK(703)'},
    {'code': '642', 'value': 'RO(642)'},
    {'code': '100', 'value': 'BG(100)'},
    {'code': '616', 'value': 'PL(616)'},
    {'code': '498', 'value': 'MD(498)'},
    {'code': '191', 'value': 'HR(191)'}
];

export default targetMarkets;