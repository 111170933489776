/* eslint-disable react-hooks/exhaustive-deps */
import { saveAs } from "file-saver";
import ExportList from './ExportList'
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import RedoIcon from '@mui/icons-material/Redo';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import { palette } from '../../../assets/Palette';
import React, { useState, useEffect } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportGlnListSearch from './ExportGlnListSearch'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';


const ExportGlnList = ({appContext}) => {

	const [rows, setRows] = useState([]);
	const [pageSize, setPageSize] = useState(5);
	const [page, setPage] = useState(0);
	const [rowCount, setRowCount] = useState(0);
	const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
	const [loading, setLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [glnSearch, setGlnSearch] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState(false);
  const [tosterMessage, setTosterMessage] = useState('');

  const [selectedItem, setSelectedItem] = useState(null);

  const [updatedRow, setUpdatedRow] = useState(null);

	useEffect(() => {
		getGlnList(true);
	}, [page, pageSize, sortModel]);

  useEffect(() => {
    if (updatedRow) {
      updateStatus(updatedRow.id[0], updatedRow.status)
    }
  }, [updatedRow]);


	const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showResetButton: true, 
      showLoader: false
    }));
  };

  const updateStatus = (recordId, newStatus) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, status: newStatus } : record
    )
    setRows(data)
  }

  const displayResetButton = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, showResetButton: status } : record
    )
    setRows(data)
  }

	const getGlnList = async (useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

		try {
      let params = {
        gln: glnSearch,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        pageNumber: page + 1,
      }
      
      const response = await appContext.getGlnList(useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
    }
	}

  const startReImportProcess = async (glnId) => {
    try {
      updateStatus(glnId, 'ready_for_process')
      const response = await appContext.startReImportProcess(glnId);
      if (response.status === 200) {
        response.json().then(res => {
          setSnackBarOpen(true)

          setMessageSeverity('success')
          setTosterMessage(`Re-Import process is started`)
        });
      } else if(response.status === 422){
        setSnackBarOpen(true)

        setMessageSeverity('error')
        setTosterMessage(`We can't initiate new process, while one is active`)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const downloadGlnCin = (glnId) => {
    try {
      let fileName = ''
      appContext.downloadGlnCin(glnId)
        .then(res => {
          let contentDisposition = res.headers.get('content-disposition').split(';')[1]
          fileName = contentDisposition.split('=')[1]
          
          return res.blob()
        }).then(blob => {
          if (blob !== undefined) {
            if (blob instanceof Blob) {
                saveAs(blob, fileName);
            } else if ('responseDescription' in blob) {
                alert(blob['responseDescription'])
            }
          }
        }).catch(err => console.error(err));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const resetGlnList = async (glnId) => {
    displayResetButton(glnId, false)
    updateStatus(glnId, 'ready_for_process')
    await appContext.resetExportGlnList(glnId)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            setSnackBarOpen(true)

            setMessageSeverity('success')
            setTosterMessage(`${glnId} - reset successfull`)
            updateStatus(glnId, 'ready_for_process')
          });
        } else {
          res.json().then(res => {
            console.log(res);
          })
        }
      });
  }

  const getGlnListWithFilter = () => {
    setPage(0)
    getGlnList(true)
    setSelectedItem(null)
  }

  const handleOnChange = (event) => {
    let fieldName = event.target.name;
    let value = event.target.value;

    switch (fieldName) {
      case 'glnSearch':
        setGlnSearch(value);
        break;
      default:
        break;
    }
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false)
  };

  const onItemSelect = (glnId) => {
    setSelectedItem(glnId);
  };

  
  
	const columns = [
    { field: 'gln', headerName: 'GLN', flex: 0.30, filterable: false, headerClassName: 'header-bold' },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.30, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.25, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
     { 
			field: 'as2Status', 
			headerName: 'Status', 
			flex: 0.25, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => {
        if (params.row.as2Status === 'PS') {
          return <Tooltip title="File Successfully Sent">
            <span>
              <IconButton size="large" style={{cursor: "pointer", color: "green"}} disabled>
                <CheckCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
      }
		 },
     {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.15,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'header-bold',
      renderCell: (params) => {
        if (params.row.status === 'ready_for_process') {
          return <Tooltip title="Processing">
            <IconButton size="large">
              <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
            </IconButton>
          </Tooltip>
        } else if(params.row.status === 'process_completed') {
          return <>
            <Tooltip title="Process Completed">
              <span>
                <IconButton size="large" style={{color: "green"}} disabled>
                  <CheckCircleIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Download" aria-label="Download">
              <IconButton
                disabled={false}
                color="primary"
                // onClick={downloadGlnCin(params.row.id)}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadGlnCin(params.row.id)
                }}
                size="large"
                id="data-request-excel-file-download-icon">
                  <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
                </IconButton>
            </Tooltip>
          </>
        } else if(params.row.status === 'with_errors') {
          return <><Tooltip title="Process Failed">
            <span>
              <IconButton size="large" style={{color: "red"}} disabled>
                <ErrorIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Reset">
            <IconButton
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                resetGlnList(params.row.id)
              }}
              size="large">
              <RestartAltIcon style={{cursor: 'pointer' }} />
            </IconButton>
          </Tooltip></>
        } else if (params.row.status === 'initialize') {
          return <Tooltip title="Start Re-Import">
            <IconButton
              disabled={isSearching}
              color="primary"
              id="article-pass-tab-search-button"
              onClick={(event) => {
                event.stopPropagation();
                startReImportProcess(params.row.id);
              }}
              size="large">
                <RedoIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}} />
            </IconButton>
          </Tooltip>
        }
      },
    },
  ];

	return (
    <div style={{ height: 650, width: '100%'}}>
      <h4 style={{fontWeight: "600"}}>Orders</h4>
      <Snackbar open={snackBarOpen} autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={handleSnackBarClose} severity={messageSeverity}>
          {tosterMessage}
        </Alert>
      </Snackbar>
      <ExportGlnListSearch
        isSearching={isSearching} 
        glnSearch={glnSearch}
        handleOnChange={handleOnChange}
        submitFunction={getGlnListWithFilter}
      />
      <div style={{paddingTop: "1rem"}}></div>
      <DataGrid
        disableColumnSelector
        loading={loading}
        rows={rows}
        columns={columns}
        paginationModel={{ page, pageSize }}
        pagination
        onPaginationModelChange={(newPaginationModel) => {
          setPage(newPaginationModel.page);
          setPageSize(newPaginationModel.pageSize);
        }}
        paginationMode="server"
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        pageSizeOptions={[5, 10, 25]}
        onRowSelectionModelChange={onItemSelect}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            width: '98% !important',
          },
          '& .MuiCircularProgress-root': {
            color: palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
        autoHeight
      />
      <div style={{paddingTop: "1rem"}}></div>
      <ExportList apiContext={appContext} glnId={selectedItem} onUpdate={setUpdatedRow}/>
    </div>
  );
	
};

export default ExportGlnList;