
import React from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {getCountryNameWithCode} from '../../common/helpers/CountryCodeHelper.js';
import {palette} from '../../assets/Palette.jsx';
import Grid from '@mui/material/Grid';
import { isGtinValid, isGlnValid, isEkgValid } from '../../common/helpers/Validator.js'
import{ getCurrentLanguage, getCookie } from '../../common/helpers/Common.js';
import { ekgCodeList } from '../../common/data/ekg_code_list';
import { countryCodes } from '../../common/data/country_code_list';
import Autocomplete from '@mui/material/Autocomplete';
import RefreshIcon from '@mui/icons-material/Refresh';

class SearchFilter extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            targetMarketSearch: this.props.targetMarketSearch,
            gtinSearch: this.props.gtinSearch,
            glnSearch: this.props.glnSearch,
            ekgSearch: this.props.ekgSearch,
            validTargetMarkets: this.props.validTargetMarkets,
            isSearching: this.props.isSearching,
            countryEkgCodeList: [],
        }

        this.handleEkgChange = this.handleEkgChange.bind(this);
        this.handleTargetMarketChange = this.handleTargetMarketChange.bind(this);
    }

    componentDidMount() {
        this.getValidTargetMarkets();

        let countryCode = getCookie('country_code')
        if ( countryCode !== "" ) {
            this.setState( { countryEkgCodeList: ekgCodeList[countryCode] })
        }
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForMarkant()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    shouldSubmitButtonBeEnabled() {
        return (isGtinValid(this.state.gtinSearch)
            && isGlnValid(this.state.glnSearch)
            && isEkgValid(this.state.ekgSearch))
    }

    handleTargetMarketChange(event){
        const targetMarket = event.target.value
        if (targetMarket === 'all') {
            this.props.handleEkgSearch('')
            this.props.handleGtinSearch('')
            this.props.handleGlnSearch('')
            this.setState({countryEkgCodeList: []})
            this.props.handleTargetMarketSearch(targetMarket)
        } else {
            let countryCode = countryCodes[targetMarket]
            this.setState({
                countryEkgCodeList: ekgCodeList[countryCode]
            }, () => this.props.handleTargetMarketSearch(targetMarket))
        }
    }

    handleEkgChange (event, newValue) {
        this.setState({
            ekgSearch: newValue
        }, () => this.props.handleEkgSearch(newValue))
    }

    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return (
            <Grid container direction="row">
                        <Grid item xs="2">
                            <TextField
                                label={t('markantDataRequestModal.targetMarket')}
                                value={this.props.targetMarketSearch}
                                onChange={this.handleTargetMarketChange}
                                select variant="outlined" size="small"
                                style={{ width: '90%', borderColor: palette.secondary.main}}
                                disabled={this.props.isSearching}
                                id="article-pass-tab-target-market-list"
                            >
                                {this.props.validTargetMarkets.map(tm => <MenuItem key={tm} value={tm}>{getCountryNameWithCode(tm, language)}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs="2">
                            <Autocomplete
                                style={{width: "90%"}}
                                id="article-pass-tab-ekg-code-list"
                                size="small"
                                value={this.props.ekgSearch}
                                onChange={this.handleEkgChange}
                                options={this.state.countryEkgCodeList}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('markantDataRequestModal.ekg')} variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs="2">
                            <TextField id="article-pass-tab-gtin-search"
                                style={{width: "90%"}}
                                label={t('markantDataRequestModal.gtin')}
                                value={this.props.gtinSearch}
                                onChange={this.props.handleOnChange} variant="outlined" size="small"
                                inputProps={{ maxLength: 14 }}
                                disabled={this.props.isSearching}
                                name="gtinSearch"
                            />
                        </Grid>
                        <Grid item xs="2">
                            <TextField id="article-pass-tab-gln-search"
                                style={{width: "90%"}}
                                label={t('markantDataRequestModal.gln')}
                                value={this.props.glnSearch}
                                onChange={this.props.handleOnChange} variant="outlined" size="small"
                                disabled={this.props.isSearching}
                                name="glnSearch"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained"
                                color="primary"
                                disabled={this.props.isSearching}
                                onClick={this.props.submitFunction}
                                style={{ height: '40px' }}
                                id="article-pass-tab-search-button"
                            >
                                {t('gdsnSubscriptionModal.search')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t('markantDataRequestModal.refresh')} aria-label={t('markantDataRequestModal.refresh')}>
                                <IconButton
                                    onClick={this.props.submitFunction}
                                    style={{marginTop: -5, marginLeft: 5}}
                                    size="large"
                                    id="article-pass-tab-refresh-button">    
                                    <RefreshIcon color="primary" style={{cursor: "pointer"}}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
        );
    }
}
  
export default withTranslation()(SearchFilter);